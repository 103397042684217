import React from 'react'
import { MenuMiCuenta } from 'components/MenuMiCuenta'
import CardWishlist from 'components/CardProduct/CardWishlist'
import ErrorType from 'pages/Error/ErrorType'
import { NO_IMAGE } from 'constants/general';

export function WishlistTemplateDesktop(props) {
    const {
        rawWishlistProducts,
        wishlistProducts,
        errorText,
        updateDelete,
        addToCart
    } = props

    return (
        <>
            <main>
                <section className="miCuentaContainer">

                    <div className="container myAccountSection">

                        <MenuMiCuenta />

                        <div className="navigationContentMyAccount">
                            <h1 className="h1MiLista">Mi Lista de Deseos</h1>
                            {rawWishlistProducts.statusCode === 200
                                ? <div className="contWishlist">
                                    {wishlistProducts.products &&
                                        wishlistProducts.products.map(({ id, images, title, price, sale_price, discount, size_color, is_digital, status, stock, reviews, shipping_price, added_at, features, brand }, ind) =>
                                            <CardWishlist
                                                key={ind}
                                                id={id}
                                                dateAdd={added_at}
                                                images={images.length >= 1 ? images[0].thumbnail : NO_IMAGE}
                                                title={title}
                                                size_color={size_color}
                                                price={price}
                                                sale_price={sale_price}
                                                discount={discount}
                                                status={status}
                                                stock={stock}
                                                reviews={reviews}
                                                shipping_price={shipping_price}
                                                is_digital={is_digital}
                                                features={features}
                                                upDelete={updateDelete}
                                                upAddCart={addToCart}

                                                brand={brand}
                                            />
                                        )
                                    }
                                    {wishlistProducts.total_items === 0
                                        ? <ErrorType
                                            section="wishlist"
                                            codeStatus={404}
                                            errorText={errorText}
                                        />
                                        : null
                                    }
                                </div>
                                : null
                            }
                            <ErrorType
                                section="wishlist"
                                codeStatus={rawWishlistProducts.statusCode}
                                errorText={errorText}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}