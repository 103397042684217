import React, { useState, useEffect } from 'react'
import './style.sass'
import { Link } from 'react-router-dom'
import { HIDDEN_DISCOUNT, NO_IMAGE, VARIANTS_PER_PORTAL } from 'constants/general'
import StarsFive from 'components/StarsFive'
import CurrencyFormat from "react-currency-format"
import { decodeStorage, encodeStorage } from 'functions/storageBase64'
import ModalPortal from 'components/Modal'
import ButtonAddToCart from 'components/ProductDetail/ShopProduct/ButtonsPayment/ButtonAddToCart'
import { repareTitleSEO, repareTextHtml } from 'functions/repareDataService'

//- funcion de modal de eliminar producto whislist
function MessageRemove(props) {
    const {
        remvoeAction,
        awaitAction
    } = props

    const elimiar = () => remvoeAction(true)

    const recapacitar = () => awaitAction(true)

    return (
        <div className="modalRemoveAddress">
            <p>¿Estas seguro que deseas eliminar este producto?</p>
            <div className="contBtns">
                <div className="btn blanco" onClick={recapacitar}>No</div>
                <div className="btn blanco" onClick={elimiar}>Si, eliminar</div>
            </div>
        </div>
    )
}

export default function CardWishlist({ ...props }) {
    const byCart = decodeStorage('idCarrito')
    var arrayProducts = decodeStorage('productsWishlist')
    const {
        id,
        dateAdd,
        images,
        title,
        reviews,
        size_color,
        price,
        sale_price,
        discount,
        features,
        shipping_price,
        status,
        stock,
        //is_digital,
        upDelete,
        upAddCart,
        brand
    } = props

    const {
        total_reviews,
        score
    } = reviews

    const {
        cex,
        cyr,
        fulfillment,
        is_digital
    } = features

    let newTitle = repareTitleSEO(title)



    const [responseFavorite, setResponseFavorite] = useState(false)
    const [removeWL, setRemoveWL] = useState(false)
    const [idArray, setIdArray] = useState('')
    const [addCartProduct, setAddCartProduct] = useState(false)
    const [showModal, SetShowModal] = useState(false)
    const sendProductToCart = { "id_product": id, "children_sku": 0, "quantity": 1 }
    //const sendProductToCart = {"id": byCart,"products":[{"id_cs": id, "children_sku": 0,"quantity": 1}]}

    // funciones para mostrar el modal
    const showModalRemove = () => SetShowModal(true)
    const handleClose = () => SetShowModal(false)

    // detona modal para eliminar producto
    const removeFavorite = () => showModalRemove()
    const sendWhsilist = () => upDelete(id)
    const noRemove = () => SetShowModal(false)

    // se ejecuta si el suario le da continuar
    const siRemove = () => {
        SetShowModal(false)
        setResponseFavorite(id)
    }

    // se ejecuta cuando el usario confirma el eliminar
    useEffect(() => {
        if (responseFavorite) {
            sendWhsilist(responseFavorite)
        }
    }, [responseFavorite])

    //manda borrar el producto del storage
    useEffect(() => {
        if (arrayProducts.includes(String(id))) {
            setIdArray(arrayProducts.indexOf(String(id)))
        }
        if (removeWL) {
            setResponseFavorite(id)
            if (idArray !== -1) {
                arrayProducts.splice(idArray, 1)
                encodeStorage('productsWishlist', arrayProducts)
            }
            setRemoveWL(false)
        }
    }, [removeWL, id])


    // ejecuta la funcion cuando el usuario le da comprar
    useEffect(() => {
        if (addCartProduct) {
            setAddCartProduct(false)
        }
    }, [addCartProduct, sendProductToCart])

    const sendToCart = () => {
        upAddCart(sendProductToCart)
        setAddCartProduct(true)
    }

    return (
        <>
            <article className="cardWishlist">
                {/* <div className={menuWish ? "menuWishlist" : "menuWishlist active"} >
                    <span onClick={toggleMenu} className="iconMenuDots"></span>
                    <ul className="menuOptionsDots">
                        <li onClick={removeWishlist}>Eliminar</li>
                    </ul>
                </div> */}
                {/* {discount >= HIDDEN_DISCOUNT &&
                    <div className="boxGeneralDiscCar">
                        <div className="bxDisc">
                            <span className="discoutnCard">-{discount}%</span>
                        </div>

                    </div>
                } */}

                <div className="contentCardWL">
                    <div className="wlL">
                        <div className="contImageCard">
                            {/* <BtnWishlist id={id}/> */}
                            <Link to={`/producto/${id}/${repareTitleSEO(newTitle)}/`} title={newTitle}>
                                <img src={images ? images : NO_IMAGE} alt={newTitle} />
                            </Link>
                        </div>
                        <div className="dataInternal">
                            <div className="dateAdd"> Agregado el: {dateAdd} </div>
                            <p className="titleProductWL">{repareTextHtml(title)}</p>
                            {total_reviews
                                ? <StarsFive score={score} comments={String(total_reviews)} />
                                : null
                            }
                            {price !== sale_price &&
                                <div className="priceDiscountContent">
                                    {discount !== 0 && sale_price !== price
                                        ? <CurrencyFormat
                                            value={price}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            renderText={value => <p className="textUnderline">{value}</p>}
                                        />
                                        : null
                                    }
                                    {discount <= HIDDEN_DISCOUNT
                                        ? null
                                        : <span className="discoutnCard">-{discount}%</span>
                                    }
                                </div>
                            }
                            {stock && status
                                ? <>
                                    <CurrencyFormat
                                        value={sale_price}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        renderText={value => <p className="priceProductWL">{value} <span className="mxn"> MXN</span></p>}
                                    />
                                </>
                                : null
                            }

                            <div className="moduleContFullFree">
                                {cyr === true &&
                                    <div className="cyr"><img src="/img/recogerTienda.png" alt="Recoger en tienda" /></div>
                                }
                                {shipping_price &&
                                    <div className="envioGratis"> Envío gratis </div>
                                }
                                {fulfillment && VARIANTS_PER_PORTAL.express === true &&
                                    <div className="fullFilment"> Express </div>
                                }
                            </div>

                            {is_digital &&
                                <div className="stickerDigital"> Producto Digital </div>
                            }

                            {stock && status
                                ? <>
                                    {Number(stock) === 1
                                        ? <div className="lastProducts">Queda 1 pieza</div>
                                        : null
                                    }
                                    {stock >= 2 && stock <= 14
                                        ? <div className="lastProducts">Últimas {stock} piezas</div>
                                        : null
                                    }
                                    {stock >= 15 && stock <= 30
                                        ? <div className="lastProducts">Últimas piezas</div>
                                        : null
                                    }
                                </>
                                : null
                            }
                            {stock && status
                                ? null
                                // ? <>
                                //     {size_color.length > 0
                                //         ?
                                //         // <Link
                                //         //     to={`/producto/${id}/${title.replace(/ /ig, '-')}`}
                                //         //     title={title}
                                //         //     className="link byProduct">
                                //         //     Ver artículo
                                //         // </Link>
                                //         null
                                //         : <div className="link addCart" onClick={sendToCart}>
                                //             Agregar a carrito
                                //         </div>
                                //     }
                                // </>
                                : <div className="messageOut">¡Producto agotado!</div>
                            }
                        </div>
                    </div>
                    <div className="wlR">
                        {stock && status && !features?.is_store_only
                            ? <>
                                <div className="addCart">
                                    {size_color.length > 0
                                        ? null//<Link to={`/producto/${id}/${repareTitleSEO(title)}`} title={title} className="showProduct">  Ver artículo </Link>
                                        : <div className="addCartBtn" onClick={sendToCart}>  Agregar a la bolsa </div>
                                    }
                                </div>
                            </>
                            : null}

                        <div className="removeItem" onClick={removeFavorite}>Eliminar</div>
                    </div>
                </div>
            </article>

            {showModal &&
                <ModalPortal type="modalSB" onClose={handleClose} closeBody="closeBody" >
                    <MessageRemove
                        remvoeAction={siRemove}
                        awaitAction={noRemove}
                    />
                </ModalPortal>
            }
        </>
    )
}

export function CardWishlistMobile({ ...props }) {
    const byCart = decodeStorage('idCarrito');
    var arrayProducts = decodeStorage('productsWishlist');
    const {
        id,
        dateAdd,
        images,
        title,
        reviews,
        size_color,
        price,
        sale_price,
        discount,
        features,
        shipping_price,
        status,
        stock,
        //is_digital,
        upDelete,
        upAddCart,
        brand
    } = props;

    const {
        total_reviews,
        score
    } = reviews;

    const {
        cex,
        cyr,
        fulfillment,
        is_digital
    } = features;

    let newTitle = repareTitleSEO(title)

    const [responseFavorite, setResponseFavorite] = useState(false);
    const [removeWL, setRemoveWL] = useState(false);
    const [idArray, setIdArray] = useState('');
    const [addCartProduct, setAddCartProduct] = useState(false);
    const [showModal, SetShowModal] = useState(false);
    const sendProductToCart = { "id_product": id, "children_sku": 0, "quantity": 1 };
    //const sendProductToCart = {"id": byCart,"products":[{"id_cs": id, "children_sku": 0,"quantity": 1}]}

    // funciones para mostrar el modal
    const showModalRemove = () => SetShowModal(true);
    const handleClose = () => SetShowModal(false);

    // detona modal para eliminar producto
    const removeFavorite = () => showModalRemove();
    const sendWhsilist = () => upDelete(id);
    const noRemove = () => SetShowModal(false);

    // se ejecuta si el suario le da continuar
    const siRemove = () => {
        SetShowModal(false);
        setResponseFavorite(id);
    }

    // se ejecuta cuando el usario confirma el eliminar
    useEffect(() => {
        if (responseFavorite) {
            sendWhsilist(responseFavorite);
        }
    }, [responseFavorite])

    //manda borrar el producto del storage
    useEffect(() => {
        if (arrayProducts.includes(String(id))) {
            setIdArray(arrayProducts.indexOf(String(id)));
        }
        if (removeWL) {
            setResponseFavorite(id);
            if (idArray !== -1) {
                arrayProducts.splice(idArray, 1);
                encodeStorage('productsWishlist', arrayProducts);
            }
            setRemoveWL(false);
        }
    }, [removeWL, id])

    // ejecuta la funcion cuando el usuario le da comprar
    useEffect(() => {
        if (addCartProduct) {
            setAddCartProduct(false);
        }
    }, [addCartProduct, sendProductToCart])

    const sendToCart = () => {
        upAddCart(sendProductToCart);
        setAddCartProduct(true);
    }

    return (
        <>
            <article className="cardWishlistMobile">
                <div className="dateAddMobile"> Agregado el: {dateAdd} </div>

                <div className="contentCardWLMobile">
                    <div className="wlLMobile">
                        <div className="contImageCardMobile">
                            <Link to={`/producto/${id}/${repareTitleSEO(newTitle)}/`} title={newTitle}>
                                <img src={images ? images : NO_IMAGE} alt={newTitle} />
                            </Link>
                        </div>

                        <div className="dataInternal">

                            <p className="titleProductWL">{title}</p>
                            {total_reviews
                                ? <StarsFive score={score} comments={String(total_reviews)} />
                                : null
                            }
                            {price !== sale_price &&
                                <div className="priceDiscountContent">
                                    {discount !== 0 && sale_price !== price
                                        ? <CurrencyFormat
                                            value={price}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            renderText={value => <p className="textUnderline">{value} MXN</p>}
                                        />
                                        : null
                                    }
                                    {discount <= HIDDEN_DISCOUNT
                                        ? null
                                        : <span className="discoutnCard">-{discount}%</span>
                                    }
                                </div>
                            }
                            <CurrencyFormat
                                value={sale_price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                renderText={value => <p className="priceProductWL">{value} <span className="mxn"> MXN</span></p>}
                            />

                            <div className="moduleContFullFreeMobile">
                                {cyr === true &&
                                    <div className="cyr"><img src="/img/recogerTienda.png" alt="Recoger en tienda" /></div>
                                }
                                {shipping_price &&
                                    <div className="envioGratis"> Envío gratis </div>
                                }
                                {fulfillment && VARIANTS_PER_PORTAL.express === true &&
                                    <div className="fullFilment"> Express </div>
                                }
                            </div>

                            {is_digital &&
                                <div className="stickerDigital"> Producto Digital </div>
                            }

                            {stock && status
                                ? <>
                                    {Number(stock) === 1
                                        ? <div className="lastProducts">Queda 1 pieza</div>
                                        : null
                                    }
                                    {stock >= 2 && stock <= 14
                                        ? <div className="lastProducts">Últimas {stock} piezas</div>
                                        : null
                                    }
                                    {stock >= 15 && stock <= 30
                                        ? <div className="lastProducts">Últimas piezas</div>
                                        : null
                                    }
                                </>
                                : null
                            }

                            {stock && status
                                ? null
                                // ? <>
                                //     {size_color.length > 0
                                //         ?
                                //         // <Link
                                //         //     to={`/producto/${id}/${title.replace(/ /ig, '-')}`}
                                //         //     title={title}
                                //         //     className="link byProduct">
                                //         //     Ver artículo
                                //         // </Link>
                                //         null
                                //         : <div className="link addCart" onClick={sendToCart}>
                                //             Agregar a carrito
                                //         </div>
                                //     }
                                // </>
                                : <div className="messageOut">¡Producto agotado!</div>
                            }

                            {stock && status
                                ? <>
                                    <div className="addCart">
                                        {size_color.length > 0
                                            ? null//<Link to={`/producto/${id}/${repareTitleSEO(title)}`} title={title} className="showProductMobile">  Ver artículo </Link>
                                            : <div className="addCartBtnMobile" onClick={sendToCart}>  Agregar a la bolsa </div>
                                        }
                                    </div>
                                </>
                                : null}

                            <div className="removeItem" onClick={removeFavorite}></div>
                        </div>
                    </div>
                </div>
            </article>

            {showModal &&
                <ModalPortal type="modalSB" onClose={handleClose} closeBody="closeBody" >
                    <MessageRemove
                        remvoeAction={siRemove}
                        awaitAction={noRemove}
                    />
                </ModalPortal>
            }
        </>

    )
}