import React, { useState, useEffect } from 'react';
import './style.sass';
import { Link } from 'react-router-dom';
import CurrencyFormat from "react-currency-format";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIsMobile } from 'hooks/useIsMobile';
import Loading from 'components/Loading';
import { repareTextHtml, repareTitleSEO } from 'functions/repareDataService';

export default function CardProductDetailDesktop({ ...props }) {
    const {
        products,
        buy_date,
        total,
        costoEnvio,
        costoProducto,
        direccionEnvio,
        entregarA,
        idFp,
        nombreFp,
        nombreStatus,
        loading
    } = props;

    let meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    // let diaSemana = ["domingo","lunes", "martes", "miércoles", "jueves", "viernes", "sábado"];
    const [nuevaFecha, setNuevaFecha] = useState();

    // tranforma la fecha en formato -> 01 de mes
    useEffect(() => {
        if (buy_date) {
            const numDia = new Date(buy_date).getDate()
            const mes = new Date(buy_date).getMonth();

            setNuevaFecha(`${numDia} de ${meses[mes]}`)
        }
    }, [buy_date])

    const [, setcopied] = useState(false);
    const [, setvalueCopy] = useState('pedido');
    const [click, setclick] = useState(false);

    const [, SetShowModal] = useState(false);
    const showDetailOrder = () => {
        SetShowModal(true);
    };

    const handleClose = () => {
        SetShowModal(false);
    };

    const onCopy = () => { setcopied(true); };

    const handleClickCopy = () => {
        setclick(!click);
    };

    const handleChangeCopy = (e) => {
        const valor = e.target.value;
        setvalueCopy(valor);
        setcopied(false);
    };

    const handleRepareTitleSEO = (nombreSEO) =>{
        if (nombreSEO) {
            return nombreSEO
        } else {
            return repareTitleSEO(nombreSEO)
        }
    }

    return (
        <>
            {loading && <Loading />}

            <div className="headOrderD">
                <p className="subtitulo">
                    <span className="spanSubtitulo bottom">Fecha de compra</span> <span className="">{nuevaFecha}</span>
                </p>

                <p className="subtitulo bottom">
                    <span className="spanSubtitulo">Producto</span> <span className="">
                        <CurrencyFormat
                            value={Number(costoProducto % 1) === 0 ? costoProducto + '.001' : costoProducto}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} renderText={value => <>${value}</>} />
                    </span>
                </p>

                <p className="subtitulo bottom">
                    <span className="spanSubtitulo">Envío</span> <span className="">
                        <CurrencyFormat
                            value={Number(costoEnvio % 1) === 0 ? costoEnvio + '.001' : costoEnvio}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} renderText={value => <>${value}</>} />
                    </span>
                </p>

                <p className="subtitulo bottom ">
                    <span className="spanSubtitulo bold">Total de pedido</span> <span className="bold separacion">
                        <CurrencyFormat
                            value={Number(total % 1) === 0 ? total + '.001' : total}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} renderText={value => <>${value}</>} />
                    </span> ({products.length}{products.length > 1 ? " artículos" : " artículo"})
                </p>


            </div>

            <div className="bodyOrderDetail">
                <p className="titleProductH2">Tu compra</p>

                {products.map((producto, ind) =>
                    <article className="cardProductOrder" key={ind}>
                        <div className="dataOrder">
                            {producto.images && 
                                <Link to={"/producto/" + producto.id + "/" + repareTitleSEO(producto?.name)}>
                                    <img src={producto.images[0].url} alt={repareTextHtml(producto?.name)} width="100" height="100" loading="lazy" />
                                </Link>
                            }
                            <div className="dataInternal">
                                <p className="titleOrder">{repareTextHtml(producto?.name)}</p>
                                {/* <p className="sendBy">Enviado por: <Link to={`/Tienda/${producto.shop.idTienda}/${producto.shop.name.replace(/[^a-zA-Z 0-9.]+/g, '').replace(/ /ig, '-').replace(/%20/ig, '-')}/`} title={producto.shop.name} className="link">{producto.shop.name}</Link></p> */}
                                <p className="precioUnitario"><span className="precio">
                                    <CurrencyFormat
                                        value={Number(producto.price % 1) === 0 ? producto.price + '.001' : producto.price}
                                        displayType={'text'}
                                        decimalScale={2}
                                        FixedDecimalScale={true}
                                        thousandSeparator={true} prefix={'$'} renderText={value => <>{value}</>} />
                                </span> <span className="moneda">MXN </span>  </p>{/* <span className="texto">Precio unitario</span> */}
                                <p className="cantidad">Cantidad: <span>{producto.quantity}</span></p>

                                {/* condicion para mostrar el tooltip en preparando tu producto */}
                                {String(producto.status).toLowerCase() !== 'preparando tu producto'
                                    ? <p className={
                                        (String(producto.status).toLowerCase() === "entregado" || String(producto.status).toLowerCase() === "en camino" || String(producto.status).toLowerCase() === "reembolsado")
                                            ? "statusOrderGreen" : "statusOrder"}>{String(producto.status).charAt(0).toUpperCase()}{String(producto.status).slice(1).toLowerCase()}</p>

                                    : <p className="textoStatusOrder">{String(producto.status).charAt(0).toUpperCase()}{String(producto.status).slice(1).toLowerCase()}
                                        <span className="ayuda tooltip bottom">
                                            <span className="tiptext">El vendedor esta preparando tu producto para que sea enviado.</span>
                                        </span>
                                    </p>
                                }

                                {/* copy entregado */}
                                {(producto.delivery_date.delivery_date !== null && String(producto.status).toLowerCase() === "entregado") && <p className="date">el {producto?.delivery_date?.delivery_date}</p>}
                                {/* copy fecha estimada de entrega */}
                                {(producto.delivery_date.estimated_delivery_date !== null &&
                                    (String(producto.status).toLowerCase() === "en camino" ||
                                        String(producto.status).toLowerCase() === "preparando tu producto" ||
                                        String(producto.status).toLowerCase() === "autorizado" ||
                                        String(producto.status).toLowerCase() === "en proceso de recolección" ||
                                        String(producto.status).toLowerCase() === "listo para pagar y recoger" ||
                                        String(producto.status).toLowerCase() === 'pedido autorizado')) &&
                                    <p className="date">{producto?.delivery_date?.estimated_delivery_date}</p>
                                }


                                {/* seccion rastreo de paquete */}
                                {(String(producto.tracking.tracking_link) != null && String(producto.tracking.tracking_link) != "null")
                                    && String(producto.status).toLowerCase() !== "reembolsado" &&
                                    String(producto.status).toLowerCase() !== "en proceso de reembolso" &&
                                    String(producto.status).toLowerCase() !== "cancelación solicitada" && String(producto.status).toLowerCase() !== "no autorizado" &&
                                    String(producto.status).toLowerCase() !== "no pagado" ?
                                    <div className="envioBox">

                                        <div className="copiarTracking">
                                            {/* <CopyToClipboard text={producto.tracking.code} onCopy={() => setcopied(true)}> */}
                                            <>
                                                <p className="numguia">Guía {producto.tracking.company_name}: <a href={producto.tracking.tracking_link} target="_blank" rel="noopener noreferrer" onClick={showDetailOrder}>{producto.tracking.code}</a></p>
                                            </>
                                            {/* </CopyToClipboard> */}

                                            {/* <CopyToClipboard text={producto.tracking.code} onCopy={() => onCopy()}>
                                                <button onClick={handleClickCopy} className="botonCopy" />
                                            </CopyToClipboard> */}
                                        </div>
                                        <div className="botonRastreo">
                                            <a className="textoRastrearEnvio" href={producto.tracking.tracking_link} target="_blank" rel="noopener noreferrer">Rastrear envío</a>
                                        </div>
                                        {/* <p className="paqueteria">Paquetería: </p> */}
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </article>
                )
                }
            </div>

            <div className="bodyPagoEnvio">
                <p className="titleProductH2">Detalles del pago</p>

                <article className="cardPago">
                    <div className="iconoPago">
                        <img src="/img/icono_mis_metodos_de_pago.svg" alt="icono-pago" />
                    </div>
                    <div className="infoPago">
                        <p className="infoBottom bold"> <CurrencyFormat
                            value={Number(total % 1) === 0 ? total + '.001' : total}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} renderText={value => <>${value}</>} />
                        </p>
                        {/* {console.log("id fp: ", idFp)} */}
                        <p className="infoBottom">{nombreFp}</p>

                        {/* {String(nombreStatus).toLowerCase() !== 'preparando tu producto'
                            ? <p className={
                                (String(nombreStatus).toLowerCase() === "entregado" || String(nombreStatus).toLowerCase() === "en camino" || String(nombreStatus).toLowerCase() === "reembolsado")
                                    ? "statusOrderGreen" : "statusOrder"}>{String(nombreStatus).charAt(0).toUpperCase()}{String(nombreStatus).slice(1).toLowerCase()}</p>

                            : String(nombreStatus).toLowerCase() === 'pendiente de autorizar'
                                ? <>
                                    <p className="statusOrder">{String(nombreStatus).charAt(0).toUpperCase()}{String(nombreStatus).slice(1).toLowerCase()}</p>
                                    <p className="textoStatusOrderGray">Estamos validando tu pago <span className="ayuda tooltip bottom">
                                        <span className="tiptext">Estamos validando tu pago</span>
                                    </span>
                                    </p>
                                </>
                                : <>
                                    <p className="textoStatusOrder">
                                        {String(nombreStatus).charAt(0).toUpperCase()}{String(nombreStatus).slice(1).toLowerCase()}
                                        <span className="ayuda tooltip bottom">
                                            <span className="tiptext">El vendedor esta preparando tu producto para que sea enviado.</span>
                                        </span>
                                    </p>
                                </>
                        } */}
                    </div>
                </article>
            </div>

            <div className="bodyPagoEnvio">
                <p className="titleProductH2">Detalles del envío</p>

                <article className="cardEnvio">
                    <div className="iconoEnvio">
                        <img src="/img/enviado.png" alt="icono-envio" />
                    </div>
                    <div className="infoEnvio">
                        <p className="bold">{entregarA}</p>
                        <p className="direccionEn">{direccionEnvio}</p>
                    </div>
                </article>
            </div>
        </>
    )
}

export function CardProductDetailMobile({ ...props }) {
    const {
        products,
        buy_date,
        total,
        costoEnvio,
        costoProducto,
        direccionEnvio,
        entregarA,
        idFp,
        nombreFp,
        nombreStatus,
        loading
    } = props;

    let meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const [nuevaFecha, setNuevaFecha] = useState();

    // tranforma la fecha en formato -> 01 de mes
    useEffect(() => {
        if (buy_date) {
            const numDia = new Date(buy_date).getDate()
            const mes = new Date(buy_date).getMonth();

            setNuevaFecha(`${numDia} de ${meses[mes]}`)
        }
    }, [buy_date])

    const [, setcopied] = useState(false);
    const [, setvalueCopy] = useState('pedido');
    const [click, setclick] = useState(false);

    const [, SetShowModal] = useState(false);
    const showDetailOrder = () => {
        SetShowModal(true);
    };

    const handleClose = () => {
        SetShowModal(false);
    };

    const onCopy = () => { setcopied(true); };

    const handleClickCopy = () => {
        setclick(!click);
    };

    const handleChangeCopy = (e) => {
        const valor = e.target.value;
        setvalueCopy(valor);
        setcopied(false);
    };


    return (
        <>
            {loading && <Loading />}

            <div className="headOrderDmobile">
                <p className="subtitulomobile">
                    <span className="spanSubtitulo bottom">Fecha de compra</span> <span className="">{nuevaFecha}</span>
                </p>

                <p className="subtitulomobile bottom">
                    <span className="spanSubtitulo">Producto</span> <span className="">
                        <CurrencyFormat
                            value={Number(costoProducto % 1) === 0 ? costoProducto + '.001' : costoProducto}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} renderText={value => <>${value}</>} />
                    </span>
                </p>

                <p className="subtitulomobile bottom">
                    <span className="spanSubtitulo">Envío</span> <span className="">
                        <CurrencyFormat
                            value={Number(costoEnvio % 1) === 0 ? costoEnvio + '.001' : costoEnvio}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} renderText={value => <>${value}</>} />
                    </span>
                </p>

                <p className="subtitulomobile bottom ">
                    <span className="spanSubtitulo bold">Total de pedido</span> <span className="bold separacion">
                        <CurrencyFormat
                            value={Number(total % 1) === 0 ? total + '.001' : total}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} renderText={value => <>${value}</>} />
                    </span> ({products.length}{products.length > 1 ? " artículos" : " artículo"})
                </p>


            </div>

            <div className="bodyOrderDetailmobile">
                <p className="titleProductH2">Tu compra</p>

                {products.map((producto, ind) =>
                    <article className="cardProductOrdermobile" key={ind}>
                        <div className="dataOrdermobile">
                            {producto.images && 
                                <Link to={"/producto/" + producto.id + "/" + String(producto.name).toLowerCase().replace(/ /g, "-").replace(/%/g, "")}>
                                    <img src={producto.images[0].url} alt={repareTextHtml(producto?.name)} width="100" height="100" loading="lazy" />
                                </Link>
                            }
                            <div className="dataInternalmobile">
                                <p className="titleOrder">{repareTextHtml(producto?.name)}</p>
                                {/* <p className="sendBy">Enviado por: <Link to={`/Tienda/${producto.shop.idTienda}/${producto.shop.name.replace(/[^a-zA-Z 0-9.]+/g, '').replace(/ /ig, '-').replace(/%20/ig, '-')}/`} title={producto.shop.name} className="link">{producto.shop.name}</Link></p> */}
                                <p className="precioUnitario"><span className="precio">
                                    <CurrencyFormat
                                        value={Number(producto.price % 1) === 0 ? producto.price + '.001' : producto.price}
                                        displayType={'text'}
                                        decimalScale={2}
                                        FixedDecimalScale={true}
                                        thousandSeparator={true} prefix={'$'} renderText={value => <>{value}</>} />
                                </span> <span className="moneda">MXN </span>  </p>{/* <span className="texto">Precio unitario</span> */}
                                <p className="cantidad">Cantidad: <span>{producto.quantity}</span></p>

                                {/* condicion para mostrar el tooltip en preparando tu producto */}
                                {String(producto.status).toLowerCase() !== 'preparando tu producto'
                                    ? <p className={
                                        (String(producto.status).toLowerCase() === "entregado" || String(producto.status).toLowerCase() === "en camino" || String(producto.status).toLowerCase() === "reembolsado")
                                            ? "statusOrderGreen" : "statusOrder"}>{String(producto.status).charAt(0).toUpperCase()}{String(producto.status).slice(1).toLowerCase()}</p>

                                    : <p className="textoStatusOrder">{String(producto.status).charAt(0).toUpperCase()}{String(producto.status).slice(1).toLowerCase()}
                                        <span className="ayuda tooltip bottom">
                                            <span className="tiptext">El vendedor esta preparando tu producto para que sea enviado.</span>
                                        </span>
                                    </p>
                                }

                                {/* copy entregado */}
                                {(producto?.delivery_date.delivery_date !== null && String(producto?.status).toLowerCase() === "entregado") && <p className="date">el {producto?.delivery_date?.delivery_date}</p>}

                                {/* copy fecha estimada de entrega */}
                                {(producto?.delivery_date.estimated_delivery_date !== null &&
                                    (String(producto.status).toLowerCase() === "en camino" ||
                                        String(producto.status).toLowerCase() === "preparando tu producto" ||
                                        String(producto.status).toLowerCase() === "autorizado" ||
                                        String(producto.status).toLowerCase() === "en proceso de recolección" ||
                                        String(producto.status).toLowerCase() === "listo para pagar y recoger" ||
                                        String(producto.status).toLowerCase() === 'pedido autorizado')) &&
                                    <p className="date">{producto?.delivery_date?.estimated_delivery_date}</p>
                                }


                                {/* seccion rastreo de paquete */}
                                {(String(producto.tracking.tracking_link) != null && String(producto.tracking.tracking_link) != "null")
                                    && String(producto.status).toLowerCase() !== "reembolsado" &&
                                    String(producto.status).toLowerCase() !== "en proceso de reembolso" &&
                                    String(producto.status).toLowerCase() !== "cancelación solicitada" && String(producto.status).toLowerCase() !== "no autorizado" &&
                                    String(producto.status).toLowerCase() !== "no pagado" ?
                                    <div className="envioBox">


                                        <div className="copiarTracking">
                                            {/* <CopyToClipboard text={producto.tracking.code} onCopy={() => setcopied(true)}> */}
                                            <>
                                                <p className="numguia">Guía {producto.tracking.company_name}: <a onClick={showDetailOrder} href={producto.tracking.tracking_link} target="_blank" rel="noopener noreferrer">{producto.tracking.code}</a></p>
                                            </>
                                            {/* </CopyToClipboard> */}

                                            {/* <CopyToClipboard text={producto.tracking.code} onCopy={() => onCopy()}>
                                                <button onClick={handleClickCopy} className="botonCopy" />
                                            </CopyToClipboard> */}
                                        </div>

                                        <div className="botonRastreo">
                                            <a className="textoRastrearEnvio" href={producto.tracking.tracking_link} target="_blank" rel="noopener noreferrer">Rastrear envío</a>
                                        </div>
                                        {/* <p className="paqueteria">Paquetería: {producto.tracking.company_name}</p> */}
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </article>
                )
                }
            </div>

            <div className="bodyPagoEnviomobile">
                <p className="titleProductH2">Detalles del pago</p>

                <article className="cardPago">
                    <div className="iconoPago">
                        <img src="/img/icono_mis_metodos_de_pago.svg" alt="icono-pago" />
                    </div>
                    <div className="infoPago">
                        <p className="infoBottom bold"> <CurrencyFormat
                            value={Number(total % 1) === 0 ? total + '.001' : total}
                            displayType={'text'}
                            decimalScale={2}
                            FixedDecimalScale={true}
                            thousandSeparator={true} renderText={value => <>${value}</>} />
                        </p>
                        {/* {console.log("id fp: ", idFp)} */}
                        <p className="infoBottom">{nombreFp}</p>

                        {/* {String(nombreStatus).toLowerCase() !== 'preparando tu producto'
                            ? <p className={
                                (String(nombreStatus).toLowerCase() === "entregado" || String(nombreStatus).toLowerCase() === "en camino" || String(nombreStatus).toLowerCase() === "reembolsado")
                                    ? "statusOrderGreen" : "statusOrder"}>{String(nombreStatus).charAt(0).toUpperCase()}{String(nombreStatus).slice(1).toLowerCase()}</p>

                            : String(nombreStatus).toLowerCase() === 'pendiente de autorizar'
                                ? <>
                                    <p className="statusOrder">{String(nombreStatus).charAt(0).toUpperCase()}{String(nombreStatus).slice(1).toLowerCase()}</p>
                                    <p className="textoStatusOrderGray">Estamos validando tu pago <span className="ayuda tooltip bottom">
                                        <span className="tiptext">Estamos validando tu pago</span>
                                    </span>
                                    </p>
                                </>
                                : <>
                                    <p className="textoStatusOrder">
                                        {String(nombreStatus).charAt(0).toUpperCase()}{String(nombreStatus).slice(1).toLowerCase()}
                                        <span className="ayuda tooltip bottom">
                                            <span className="tiptext">El vendedor esta preparando tu producto para que sea enviado.</span>
                                        </span>
                                    </p>
                                </>
                        } */}
                    </div>
                </article>
            </div>

            <div className="bodyPagoEnviomobile">
                <p className="titleProductH2">Detalles del envío</p>

                <article className="cardEnvio">
                    <div className="iconoEnvio">
                        <img src="/img/enviado.png" alt="icono-envio" />
                    </div>
                    <div className="infoEnvio">
                        <p className="bold">{entregarA}</p>
                        <p className="direccionEn">{direccionEnvio}</p>
                    </div>
                </article>
            </div>
        </>
    )
}

export function CardProductDetail({ ...props }) {
    const {
        products,
        buy_date,
        total,
        costoEnvio,
        costoProducto,
        direccionEnvio,
        entregarA,
        idFp,
        nombreFp,
        nombreStatus,
    } = props;
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <CardProductDetailDesktop
                        location={props.location}
                        total={total} buy_date={buy_date} products={products}
                        costoEnvio={costoEnvio}
                        costoProducto={costoProducto}
                        direccionEnvio={direccionEnvio}
                        entregarA={entregarA}
                        idFp={idFp}
                        nombreFp={nombreFp}
                        nombreStatus={nombreStatus}
                    />
                    : isMobile === 'movile'
                        ? null
                        : <CardProductDetailMobile
                            location={props.location}
                            total={total} buy_date={buy_date} products={products}
                            costoEnvio={costoEnvio}
                            costoProducto={costoProducto}
                            direccionEnvio={direccionEnvio}
                            entregarA={entregarA}
                            idFp={idFp}
                            nombreFp={nombreFp}
                            nombreStatus={nombreStatus}
                        />
                : null
            }
        </>
    )
}